// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-liens-js": () => import("./../src/pages/liens.js" /* webpackChunkName: "component---src-pages-liens-js" */),
  "component---src-pages-prologue-js": () => import("./../src/pages/prologue.js" /* webpackChunkName: "component---src-pages-prologue-js" */),
  "component---src-pages-qui-suis-je-js": () => import("./../src/pages/qui-suis-je.js" /* webpackChunkName: "component---src-pages-qui-suis-je-js" */),
  "component---src-pages-strategies-js": () => import("./../src/pages/strategies.js" /* webpackChunkName: "component---src-pages-strategies-js" */),
  "component---src-pages-table-to-column-js": () => import("./../src/pages/table-to-column.js" /* webpackChunkName: "component---src-pages-table-to-column-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-prologue-part-js": () => import("./../src/templates/prologue-part.js" /* webpackChunkName: "component---src-templates-prologue-part-js" */)
}

